import { useState, useEffect } from 'react';

import DateAndTime from '../DateAndTime/DateAndTime';

import SubmitForm from '../SubmitForm/SubmitForm';

// import Lottie from 'lottie-react';
// import doneAnimation from '../../animations/done.json';

import logoServices from '../../photos/logoPredental.png';

import './Main.css';

const Main = ({ data, bookDone, staffs, adsID }) => {
  const [sections, setSections] = useState({
    section1: true,
    section2: false,
    section3: false,
  });

  const [added, setAdded] = useState(false);

  useEffect(() => {
    const staffData = staffs;

    const defaultStaff = staffData.filter(
      (staff) => staff.id === '111924000000024006'
    );

    //const randomStaff = Math.floor(Math.random() * staffData.length);

    setDataSelected({
      staff: {
        name: defaultStaff[0].name,
        id: defaultStaff[0].id,
      },
      glID: adsID,
    });
  }, [staffs, adsID]);

  const changeStaff = (name, id) => {
    setDataSelected({ ...dataSelected, staff: { name, id } });
  };

  const changeScreen = () => {
    setAdded(true);
  };

  const [firstLoad, setFirstLoad] = useState(true);
  const [firstLoadForm, setFirstLoadForm] = useState(true);

  const [dataSelected, setDataSelected] = useState({});

  const hoverEffect = firstLoad ? '' : 'hover-effect';
  const hoverForm = firstLoadForm ? '' : 'hover-effect';

  const addData = (name, duration, serviceID) => {
    if (name && duration) {
      setDataSelected({
        ...dataSelected,
        serviciu: { name, duration, serviceID },
      });

      setSections({ section1: false, section3: false, section2: true });
      setFirstLoad(false);
    }
  };

  const handleSection3 = (date) => {
    setFirstLoadForm(false);
    setDataSelected({ ...dataSelected, date: date });
    setSections({ section1: false, section3: true, section2: false });
  };

  if (added) {
    return (
      <div className="text-center">
        <hr></hr>
        <h1>Te-ai programat cu succes!</h1>
        <p>Data ora etc...</p>
        <p>Ai primit un email cu toate detaliile, o zi buna!</p>
      </div>
    );
  }

  return (
    <div className="container-main">
      <div className="left-side">
        <div
          onClick={() => {
            setSections({ section1: true, section3: false, section2: false });
          }}
          className={
            !sections.section1
              ? 'service hover-effect'
              : 'service hover-effect active'
          }
        >
          <div>
            <i className="fas fa-briefcase me-2"></i>{' '}
            {dataSelected.serviciu ? (
              <>
                {dataSelected.serviciu.name}
                <div className="text-center">
                  <i className="far fa-clock"></i>{' '}
                  {dataSelected.serviciu.duration.replace('mins', 'minute')}
                </div>
              </>
            ) : (
              'Serviciu'
            )}
          </div>

          <div>
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
        <hr />
        <div
          onClick={() => {
            if (!firstLoad) {
              setSections({ section1: false, section3: false, section2: true });
            }
          }}
          className={
            !sections.section2
              ? `service ${hoverEffect}`
              : 'service hover-effect active'
          }
        >
          <div>
            <i className="far fa-calendar-alt me-2"></i> Data și ora
            {dataSelected?.date && <p>{dataSelected.date}</p>}
          </div>

          <div>
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
        <hr />

        <div
          onClick={() => {
            if (!firstLoadForm) {
              setSections({ section1: false, section3: true, section2: false });
            }
          }}
          className={
            !sections.section3
              ? `service ${hoverForm}`
              : 'service hover-effect active'
          }
        >
          <div>
            <i className="far fa-user me-2"></i> Informațiile dvs.
          </div>

          <div>
            <i className="fas fa-chevron-right"></i>
          </div>
        </div>
        <hr />
      </div>

      <div className="vl"></div>

      <div className="right-side">
        {sections.section1 &&
          data.map((el, i) => {
            return (
              <div key={el.name}>
                <div
                  className="service-right"
                  onClick={() => addData(el.name, el.duration, el.id)}
                >
                  <div className="service-name">
                    <div>
                      <img
                        src={logoServices}
                        alt="Service"
                        style={{ maxWidth: '70px', maxHeight: '70px' }}
                        className="me-3"
                      />
                    </div>
                    <p>{el.name}</p>
                  </div>
                  <div className="service-duration">
                    {el.duration.replace('mins', 'minute')}
                    {'   '}
                    {el.name === dataSelected?.serviciu?.name ? (
                      <i className="fas fa-check-circle"></i>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <hr />
              </div>
            );
          })}

        {sections.section2 && (
          <DateAndTime
            changeData={handleSection3}
            sections={sections}
            dataSelected={dataSelected}
            staffData={staffs}
            handleChangeStaff={changeStaff}
          />
        )}

        {sections.section3 && (
          <SubmitForm
            changeScreen={changeScreen}
            data={dataSelected}
            bookDone={bookDone}
            adsID={adsID}
          />
        )}
      </div>
    </div>
  );
};
export default Main;
