const Header = () => {
  return (
    <div
      className="text-center mt-4"
      style={{ width: '70%', margin: '0 auto' }}
    >
      <h3 className="mb-3 pt-3">Programare online rapidă</h3>
      <p style={{ fontSize: '0.8rem' }}>
        Alegeți tipul de programare din lista de mai jos. Dacă este pentru prima
        dată când veniți la noi, vă rugăm să alegeți tipul programării
        “Consultație”, alegeți data și ora preferată, apoi completați cu datele
        personale necesare. Pentru orice fel de informații, vă stăm la
        dispoziție la numărul de telefon{' '}
        <a href="tel:0799 994 904">0799 994 904</a>
      </p>
    </div>
  );
};

export default Header;
