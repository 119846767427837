import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Calendar } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';

import Lottie from 'lottie-react';
import loadingDots from '../../animations/loadingDots.json';

import './DateAndTime.css';

// import timeSlots1 from '../../timeSlots.json';
const DateAndTime = ({
  changeData,
  sections,
  dataSelected,
  staffData,
  handleChangeStaff,
}) => {
  const [dateValue, setDateValue] = useState(new Date());
  const [dateTime, setDateTime] = useState('');
  const [timeSlots, setTimeSlots] = useState(null);
  const [overlay, setOverlay] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const getSlots = async () => {
      try {
        const formatDate = `${dateValue.getDate()}-${
          dateValue.getMonth() + 1
        }-${dateValue.getFullYear()}`;
        // console.log(formatDate);

        const getTimes = await fetch(
          `/server/production_bookings_project_function/slots/${formatDate}/${dataSelected.staff.id}`,
          { signal: abortController.signal }
        );
        const getTimesResponse = await getTimes.json();

        const hoursToFilter = getTimesResponse.data.returnvalue.data;

        let filteredHours;

        if (dataSelected.staff.id === '111924000001226078') {
          filteredHours = hoursToFilter.filter(
            (el) => el > '09:45' && el < '16:45'
          );
        } else {
          filteredHours = hoursToFilter.filter(
            (el) => el > '11:45' && el < '19:45'
          );
        }

        setTimeSlots(filteredHours);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    getSlots();

    return () => {
      abortController.abort();
    };
  }, [dateValue, dataSelected]);

  // console.log(dataSelected?.date?.split(',')[0].trim());
  // console.log(dateValue.toISOString());
  // console.log(dataSelected?.date?.split(',')[0].trim() === dateValue);
  /* eslint-disable */
  const checkData =
    dataSelected?.date?.split(',')[0].split('-')[0] == dateValue.getDate() &&
    dataSelected?.date?.split(',')[0].split('-')[1] == dateValue.getMonth() + 1;
  /* eslint-disable */

  const handleSelect = (date) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      // alert(date);
      return;
    }
    setLoading(true);
    setDateValue(date);
  };

  const handleDateChange = (hour) => {
    const formatDate = `${dateValue.getDate()}-${
      dateValue.getMonth() + 1
    }-${dateValue.getFullYear()}`;

    setDateTime(hour);
    changeData(formatDate + ', ' + hour);
  };

  if (!timeSlots) {
    return <Lottie style={{ height: '200px' }} animationData={loadingDots} />;
  }

  return (
    <div>
      {!sections.section3 && (
        <>
          {overlay && (
            <div
              className="overlayPerson"
              onClick={() => setOverlay((prevState) => !prevState)}
            >
              <div
                onClick={(e) => e.stopPropagation()}
                className="data-overlay shadow-lg mb-5 bg-body rounded"
              >
                {staffData.map((el, i) => {
                  return (
                    <div
                      key={i}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeStaff(el.name, el.id);
                        setOverlay((prevState) => !prevState);
                      }}
                      className="staffItem mt-3 mb-3"
                      style={{ cursor: 'pointer' }}
                    >
                      {el.id === '111924000000024006' ? (
                        <img
                          src="https://www.predental.ro/MPX00329-Edit.jpg"
                          style={{ maxWidth: '70px', maxHeight: '70px' }}
                        />
                      ) :  el.id === '111924000001226078' ? (
                        <img
                          src="https://i.imgur.com/ort1AlR.png"
                          style={{ maxWidth: '70px', maxHeight: '70px' }}
                        />
                      ) : (
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png?20150327203541"
                          style={{ maxWidth: '70px', maxHeight: '70px' }}
                        />
                      )}
                      <p className="ms-3">{el.name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {staffData.length > 1 ? (
            <p className="mb-4" style={{ fontSize: '18px' }}>
              Programarea o sa fie cu{' '}
              <span style={{ fontWeight: 'bold' }}>
                {dataSelected.staff.name},
              </span>
              <span
                onClick={() => setOverlay((prevState) => !prevState)}
                style={{ color: '#0D6EFD', cursor: 'pointer' }}
                className="ms-2"
              >
                Alege alt doctor
              </span>
            </p>
          ) : (
            <p className="mb-4">
              Programarea o sa fie cu{' '}
              <span style={{ fontWeight: 'bold' }}>
                {dataSelected.staff.name}
              </span>
            </p>
          )}

          <hr></hr>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Calendar
              locale={locales['ro']}
              date={dateValue}
              color="#0D6EFD"
              minDate={new Date()}
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear() + 1))
              }
              onChange={handleSelect}
            />
          </div>
          <hr></hr>
          <div className="row mt-5 text-center">
            {timeSlots.length > 0 &&
              !loading &&
              timeSlots.map((el, i) => (
                <div key={i} className="col-md-3 col-xs-8 col-sm-6 mb-4">
                  <button
                    disabled={el.toLowerCase() === 'slots not available'}
                    type="button"
                    style={{ width: '100%' }}
                    onClick={(e) => handleDateChange(e.target.textContent)}
                    className={`btn btn-sm ${
                      (el === dateTime && checkData) ||
                      (dataSelected?.date?.split(',')[1].trim() === el &&
                        checkData)
                        ? 'btn-primary'
                        : 'btn-outline-primary'
                    }`}
                  >
                    {el.toLowerCase() === 'slots not available' ? (
                      <span style={{ fontWeight: 'bold' }}>
                        În acest interval nu sunt locuri libere pentru
                        programări. Vă rugăm alegeți altă zi sau alt medic
                      </span>
                    ) : (
                      el
                    )}
                  </button>
                </div>
              ))}

            {!loading && timeSlots.length < 1 && (
              <span style={{ fontWeight: 'bold' }}>
                În acest interval nu sunt locuri libere pentru programări. Vă
                rugăm alegeți altă zi sau alt medic
              </span>
            )}

            {loading && (
              <Lottie style={{ height: '200px' }} animationData={loadingDots} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DateAndTime;
