import { useState } from 'react';
import Lottie from 'lottie-react';
import validator from 'validator';
import classNames from 'classnames';
import './SubmitForm.css';

import loadingDots from '../../animations/loadingDots.json';

const SubmitForm = ({ bookDone, data, adsID }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');

  const [error, setError] = useState(false);

  const [send, setSend] = useState(false);

  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneValid, setPhoneValid] = useState(false);
  const [isNameValid, setNameValid] = useState(false);

  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(false);

  //console.log('The data=>', data);
  //console.log(check1);
  // console.log(check2);
  const handleSubmit = async () => {
    setSend(true);
    const sendData = await fetch(
      '/server/production_bookings_project_function/services',
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          service_id: data.serviciu.serviceID,
          staff_id: data.staff.id,
          customer_details: { name: name, email: email, phone_number: phone },
          additional_fields: {
            Descriere: description,
            googleGLCID: adsID,
          },
          from_time: data.date.split(',').join(''),
        }),
      }
    );

    const dataResponse = await sendData.json();
    //console.log(dataResponse);
    if (dataResponse.status === 'success') {
      setSend(false);
      bookDone(data, email, name);
    } else {
      //console.log('error');
      setError(true);
    }
  };

  if (error) {
    return <h1>Ceva nu a mers ok!</h1>;
  }

  return (
    <>
      {send && (
        <div className="overlaySend">
          <div className="loadingContainer">
            <Lottie style={{ height: '200px' }} animationData={loadingDots} />
          </div>
        </div>
      )}
      <div className="text-center">
        <h4 className="mt-2 mb-4">Completați datele dumneavoastră </h4>
      </div>

      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="mb-3">
            <label htmlFor="formGroupExampleInput" className="form-label">
              Nume <span className="text-danger">*</span>
            </label>
            <input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (e.target.value.length >= 4) {
                  setNameValid(true);
                } else {
                  setNameValid(false);
                }
              }}
              type="text"
              className={classNames({
                'form-control': name.length < 1,
                'form-control is-invalid': !isNameValid && name.length > 0,
                'form-control is-valid': isNameValid,
              })}
              id="formGroupExampleInput"
              placeholder="Adauga numele..."
            />
            <div className="form-text">Numele sa contina minim 4 caractere</div>
          </div>
          <div className="mb-3">
            <label htmlFor="formGroupExampleInput2" className="form-label">
              Email <span className="text-danger">*</span>
            </label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (validator.isEmail(e.target.value)) {
                  setIsEmailValid(true);
                } else {
                  setIsEmailValid(false);
                }
              }}
              type="email"
              className={classNames({
                'form-control': email.length < 1,
                'form-control is-invalid':
                  isEmailValid === false && email.length > 0,
                'form-control is-valid': isEmailValid,
              })}
              id="formGroupExampleInput2"
              placeholder="Adauga email..."
            />
          </div>

          <div className="mb-3">
            <label htmlFor="formGroupExampleInput3" className="form-label">
              Telefon <span className="text-danger">*</span>
            </label>
            <input
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                if (validator.isMobilePhone(e.target.value, ['ro-RO'])) {
                  setPhoneValid(true);
                } else {
                  setPhoneValid(false);
                }
              }}
              type="phone"
              className={classNames({
                'form-control': phone.length < 1,
                'form-control is-invalid':
                  isPhoneValid === false && phone.length > 0,
                'form-control is-valid': isPhoneValid,
              })}
              id="formGroupExampleInput3"
              placeholder="Adauga numarul de telefon..."
            />
          </div>

          <div className="mb-3">
            <label htmlFor="exampleFormControlTextarea4" className="form-label">
              Descriere
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
              placeholder="Adauga mai multe detalii daca este cazul...."
              id="exampleFormControlTextarea4"
              rows="3"
            ></textarea>

            <div className="mb-3 mt-3">
              <div className="form-check">
                <input
                  onChange={() => setCheck1((prevState) => !prevState)}
                  className="form-check-input"
                  type="checkbox"
                  checked={check1}
                />
                <label
                  style={{ userSelect: 'none' }}
                  className="form-check-label"
                  for="flexCheckDefault"
                >
                  <span className="text-danger">*</span> Sunt de acord sa fiu
                  contactat pe email sau telefonic – Predental NU vă va contacta
                  în scopuri publicitare, ci doar legat de această programare
                </label>
              </div>

              <div className="form-check mt-4">
                <input
                  onChange={() => setCheck2((prevState) => !prevState)}
                  className="form-check-input"
                  type="checkbox"
                  checked={check2}
                />
                <label
                  style={{ userSelect: 'none' }}
                  className="form-check-label"
                  for="flexCheckDefault"
                >
                  <span className="text-danger">*</span> Sunt de acord și am
                  luat la cunoștință despre{' '}
                  <a
                    href="https://www.predental.ro/politca-de-prelucrare-a-datelor-cu-caracter-personal"
                    target="__blank"
                  >
                    politca de prelucrare a datelor cu caracter personal
                  </a>
                </label>
              </div>
            </div>

            <div className="d-grid gap-2">
              <button
                type="button"
                onClick={handleSubmit}
                className="btn text-white mt-4"
                style={{ backgroundColor: '#0057bb' }}
                disabled={
                  !isNameValid ||
                  !isPhoneValid ||
                  !isEmailValid ||
                  !check1 ||
                  !check2
                }
              >
                Programeaza-te
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </>
  );
};

export default SubmitForm;
